import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import GoogleMap from '../components/googleMap';
import ContactBar from '../components/contactBar';
import About from '../components/about';
import Testimonials from '../components/testimonials';
import PriceList from '../components/priceList';

const HomePage = function () {
  return (
    <Layout>
      <Seo
        title="Sugarr - Natural Hair Removal"
        description="Body Sugaring is an all natural method of body hair removal. Sugaring is excellent for all skin types, leaves skin soft and subtle for weeks"
      />
      <PriceList />
      <Testimonials />
      <About />
      <ContactBar />
      <GoogleMap />
    </Layout>
  );
};

export default HomePage;
